import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image004.webp"


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <ul>
                <li><strong>Camera List</strong>: Add or edit your cameras and interactive site plans.</li>
                <li><strong>Layout</strong>: Arrange your cameras according to your needs in layout presets.</li>
                <li><strong>Camera PTZ</strong>: Control your camera´s Pan, Tilt and Zoom functions.</li>
                <li><strong>Image Adjust</strong>: Adjust your camera´s image parameters.</li>
                <li><strong>Webserver</strong>: Let InstarVision stream your camera´s video to the internet or embed the video in your website.</li>
                <li><strong>Record</strong>: Use the software to record scheduled or alarm-triggered videos and manage FTP uploads and Email notifications.</li>
                <li><strong>System</strong>: Adjust the UI language, start-up view and TCP ports used for the remote access.</li>
                <li><strong>Users</strong>: Multi-level user management.</li>
                <li><strong>Advanced</strong>: Check the software log, import/export system settings and start the InstarVision video player.</li>
                <li><strong>About InstarVision</strong>: General Information about your software installation.</li>
              </ul>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}

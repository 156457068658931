import React from 'react'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui'

import ivImage from "../../../../images/Software/Windows/InstarVision/Image003.webp"


export default function ImageCard() {

  return (
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiImage
              hasShadow
              allowFullScreen
              alt="InstarVision v2 for Windows"
              src={ivImage}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>Once the software is registered this dialog will no longer be displayed, but can be accessed from the <em>Advanced menu</em> in the Side Navigation Bar in the software´s main window. In case you want to upgrade the software, just delete your old license key, copy & paste the new key into the input field and click on <em>Register Online</em></p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
  );
}

import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { withStyles} from '@material-ui/core/styles'
import {
  Grid, Table, TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

const styles = {
  customCell: {
    whiteSpace: "normal",
    wordWrap: "break-word"
  }
}

const CustomTableCellBase = ({ classes, ...restProps }) => (
  <Table.Cell className={classes.customCell} {...restProps} />
)

const CustomTableBase = ({ style, ...restProps }) => (
  <Table.Table
    style={{
      ...style,
      minWidth: 0
    }}
    {...restProps}
  />
)

const CustomTableCell = withStyles(styles)(CustomTableCellBase);
const CustomTable = withStyles(styles)(CustomTableBase);

class AudioTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "actionColumn", title: "Audio Settings" },
        { name: "descriptionColumn", title: "Description" }
      ],
      rows: [
        { actionColumn: 'Enable audio', descriptionColumn: 'Activate or deactivate the audio input (microphone) of your camera.' },
        { actionColumn: 'Input sensitivity', descriptionColumn: 'The audio input volume controls the internal or (optional) external microphone of your camera. Please be aware, that the volume indirectly influences the sensitivity of the Audio Detection.' },
        { actionColumn: 'Speaker', descriptionColumn: 'Activate or deactivate the audio output (speaker) of your camera.' },
        { actionColumn: 'Output volume', descriptionColumn: 'Here you can adjust the audio volume for the internal or optional external loudspeaker of your camera.' },
        { actionColumn: 'Active Noise Reduction (ANR)', descriptionColumn: 'Enable the active noise reduction for your cameras audio recording.' },
        { actionColumn: 'ANR Level', descriptionColumn: 'Set the percentage of noise reduction that should be used.' },
        { actionColumn: 'Active Echo Cancellation (AEC)', descriptionColumn: 'Enable the active echo cancellation for your cameras audio recording.' },
      ]
    };
  }
  render() {
    const { rows, columns } = this.state;

    return (
      <Paper style={{display: 'inline-block', maxWidth: '915px'}}>
        <Grid rows={rows} columns={columns}>
          <Table tableComponent={CustomTable} cellComponent={CustomTableCell} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    );
  }
}

AudioTable.propTypes = {
  classes: PropTypes.object.isRequired,
}


export default withStyles(styles)(AudioTable)